.Toastify {
  z-index: 9999;
}
.pagination .active button {
  color: white !important;
}

.autocomplete-dropdown-container {
  position: absolute;
  width: auto;
  background-color: white;
  z-index: 9999;
}

.autocomplete-dropdown-container .suggestion-item,
.autocomplete-dropdown-container .suggestion-item--active {
  background-color: white;
  border-style: solid;
  border-color: #d1d3e2;
  border-width: 0 1px;
  border-collapse: collapse;
}

.autocomplete-dropdown-container .suggestion-item:hover,
.autocomplete-dropdown-container .suggestion-item--active:hover {
  background-color: "#fafafa";
  cursor: "pointer";
}

.autocomplete-dropdown-container .suggestion-item:first,
.autocomplete-dropdown-container .suggestion-item--active:first {
  border-top-width: 1px;
}

.autocomplete-dropdown-container .suggestion-item:last-child,
.autocomplete-dropdown-container .suggestion-item--active:last-child {
  border-bottom-width: 1px;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}
