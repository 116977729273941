@media print {
  @page {
    size: A4 landscape;
    margin: 2cm;
  }
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
}

@media print {
  .switch-wrapper > div {
    position: relative;
  }
  .page-break {
    page-break-after: always;
  }
}

.print {
  font-size: 8px;
}

.print .table td,
.print .table th {
  padding: 0 2px;
}
